<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-[101]" @close="closePopup">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                             leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen h-screen overflow-y-clip">
                <div class="flex max-h-full items-start justify-center text-center">
                    <TransitionChild as="template" enter="ease-out duration-300"
                                     enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                     enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                     leave-from="opacity-100 translate-y-0 sm:scale-100"
                                     leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                                class="relative transform overflow-hidden rounded-lg h-screen py-4 text-left shadow-xl transition-all">
                            <img :src="imageUrl" alt="Banner Image"
                                 class="max-w-screen w-full h-full object-contain rounded-md"/>
                            <div @click="closePopup" class="top-10 right-5 w-10 h-10 bg-brand-color-400 rounded-full absolute flex flex-col justify-center items-center cursor-pointer">
                                <Icon name="material-symbols:close-rounded" class="text-white w-6 h-6 mx-auto hover:w-7 hover:h-7 transition duration-100"/>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue'

const {imageUrl, onClosePopup} = defineProps(['imageUrl', 'onClosePopup'])
const open = ref<boolean>(true)
const closePopup = () => {
    open.value = false;
    onClosePopup(false);
}

</script>